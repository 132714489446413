@import "shared/styles/media.scss";

.review_score_box {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 8px;

  @include md {
    border-radius: 999px;
    background: var(--UIMain-Gray-Background, #f2f2f2);
    padding: 10px 15px;
    margin-bottom: 20px;
  }

  .score {
    color: var(--UIMain-Black, #000);

    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;

    @include md {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
    }
  }

  .stars {
    display: flex;
    flex-direction: row;
    gap: 1px;
    align-items: center;

    .star {
      color: #ffa336;
      display: flex;
      align-items: center;

      svg {
        width: 14px;
        height: 14px;
        flex-shrink: 0;
      }

      @include md {
        svg {
          width: 20px;
          height: 20px;
          flex-shrink: 0;
        }
      }
    }
  }

  .label {
    color: var(--UIMain-Gray, #8b8b8b);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;

    @include md {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}
