.track_orders_telegram_card {
  display: flex;
  flex-direction: column;
  background: hsla(0, 0%, 95%, 1);
  padding: 15px;
  border-radius: 10px;
  gap: 15px;
  width: 100%;

  .header {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .title {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      gap: 10px;

      font-size: 20px;
      font-weight: 500;
      line-height: 26px;
      text-align: left;

      .telegram_icon {
        display: flex;
        flex-shrink: 0;
        width: 44px;
        height: 44px;
      }
    }

    .description {
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      text-align: left;
      color: hsla(0, 0%, 32%, 1);
    }
  }

  .button {
    background: hsla(0, 0%, 0%, 1);
    width: 100%;
    max-height: none;
    padding: 14px 25px 14px 25px;
    gap: 10px;
    border-radius: 10px;

    color: hsla(0, 0%, 100%, 1);

    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    text-align: left;
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;

    .icon {
      display: flex;
      flex-shrink: 0;
      width: 22px;
      height: 22px;
    }
  }
}
