.product_description {
  width: 100%;
  font-family: var(--font);
  color: var(--text-color);
}

.head {
  color: var(--text-color);
  margin-bottom: 10px;

  .title {
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    display: flex;
    align-items: baseline;
    color: var(--text-color);

    a {
      text-decoration: underline;
    }
  }

  .description {
    margin-top: 10px;
    line-height: 140%;
    color: var(--medium-gray);
  }
}

.content {
  font-size: 16px;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;

  p {
    display: block;
    margin-bottom: 5px;
  }

  ul {
    margin-left: 1.2em;
    padding: 0;
    list-style-type: disc;

    li + li {
      margin-top: 10px;
    }
  }
}
