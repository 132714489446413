@import "shared/styles/media";

.product_list_skeleton {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  margin: 0 -8px;

  @include lg {
    grid-template-columns: repeat(3, 1fr);
  }

  @include md {
    grid-template-columns: repeat(2, 1fr);
  }

  .product {
    position: relative;
    height: 220px;

    &:hover {
      z-index: 2;
    }
  }
}

.product_skeleton {
  height: 220px;
  display: flex;
  width: 100%;
  border-radius: 10px;
}
