.brand_and_name {
}

.brand {
  //margin-top: 10px;
  width: 100%;
  //height: 48px;
  height: 12.21374046vw;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: left;
  }
}

.name {
  margin-top: 2vw;
  margin-bottom: 4vw;

  max-width: 80%;
  font-size: 64px;
  font-size: 16.28vw;
  font-style: normal;
  font-weight: 900;
  line-height: 93.75%;
  margin-left: -3px;
  //max-height: 2.85em;
  //overflow: hidden;

  &:hover,
  &:active {
    position: relative;
    z-index: 2;
  }
}
