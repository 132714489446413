@import "shared/styles/media.scss";
@import "shared/styles/typography.scss";

.heading {
  padding: 40px var(--container-horizontal-padding);
  border-bottom: 1px solid var(--stroke-color);

  @include lg {
    --container-horizontal-padding: 20px;
  }

  @include md {
    padding-bottom: 24px;
    --container-horizontal-padding: 16px;
  }

  .compound_header {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .search_row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 5px 40px;
    width: 100%;
    flex-direction: column;

    .brands,
    .models {
      @include md {
        display: flex;
      }
    }

    .brands {
      margin-top: 15px;
      @include md {
        margin-top: 0;
      }
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    @include md {
      margin-bottom: 10px;
    }

    .title {
      width: auto;
      flex-shrink: 0;
      color: #000000;

      font-family: var(--font);
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;

      @include md {
        font-style: normal;
        font-weight: 800;
        font-size: 22px;
        line-height: 130%;

        margin-bottom: 5px;
      }
    }

    @include lg {
      gap: 10px;
      .search_row {
        flex-direction: column;
        gap: 5px;
      }
    }
  }
}

.searchBoxWrapper {
  width: calc(100% - 184px);
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  @include md {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
  }
}

.searchBoxLabel {
  font-family: var(--font);
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  color: #8b8b8b;
}

.searchBox {
  width: 100%;
  max-width: 776px;
  padding: 12px 9px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid #E0E0E0;
  background: #FFF;
  cursor: text;
  outline: none;

  @include md {
    padding: 8px 10px 7px 10px;
    border: none;
    background: #F2F2F2;
  }
  .main {
    display: flex;
    align-items: center;
    gap: 10px;
    svg {
      @include md {
        display: none;
      }
    }
    h1 {
      font-family: var(--font);
      font-size: 18px;
      font-weight: 400;
      line-height: 140%;
      text-overflow: ellipsis;
      white-space: none;
      overflow: hidden;
      color: #000000;
      @include md {
        font-size: 16px;
        color: #000000;
      }
    }
  }
  button {
    width: 25px;
    height: 25px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
  }
}