.tags_and_heart {
}

.original {
  position: relative;
  width: fit-content;
}

.original_main {
  display: flex;
  gap: 4px;
  align-items: center;

  color: #58c94e;

  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.help {
  margin-top: 1.5px;

  display: flex;
  gap: 4px;
  align-items: center;

  color: var(--Text-black, #222);
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px; /* 100% */
}

.categories {
  margin-top: 9px;
  display: flex;
  flex-direction: column;
  gap: 4px;

  color: var(--Dark-gray, #92929f);
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.favorite {
  color: #1b1b1b;
}

.heart {
  margin-left: -12px;

  svg {
    color: var(--Text-black, #222);
  }
}
