.stage {
  padding: 20px;
  gap: 10px !important;
  align-items: center !important;
  font-family: var(--font);

  .steps {
    display: flex;
    align-items: center;
    .step {
      display: flex;
      align-items: center;
      .step_inner {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 9999px;
        background: #F1F0EE;
        svg {
          path {
            stroke: #8B8B8B;
          }
        }
      }
      .line {
        width: 25px;
        height: 1px;
        background: #BEBEBE;
      }
      .active {
        background: var(--primary);
        svg {
          path {
            stroke: #FFFFFF;
          }
        }
      }
      .done {
        background: var(--primary);
        svg {
          path {
            stroke: #FFFFFF;
          }
        }
      }
      .lineColor {
        background: var(--primary);
      }
    }
  }

  .texts {
    display: flex;
    align-items: center;
    flex-direction: column;
    .deliveryDate {
      font-size: 22px;
      font-weight: 700;
      line-height: 130%;
      text-align: center;
      color: #000000;
    }

    .status {
      font-size: 16px;
      font-weight: 400;
      line-height: 130%;
      text-align: center;
      color: #000000;
    }

    .address {
      margin-top: 10px;
      font-size: 14px;
      font-weight: 400;
      line-height: 130%;
      text-align: center;
      color: #000000;
    }

    .yandexMaps {
      margin-top: 10px;
      svg {
        width: auto;
        height: auto;
      }
    }

    .deliveryTrack {
      width: 100%;
      max-width: 360px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      gap: 10px;
      .trackNumber {
        color: #000;
        font-size: 12px;
        font-weight: 500;
        line-height: 120%;
        .value {
          width: fit-content;
          color: #0085FF;
          cursor: pointer;
        }
      }
    }
  }
}