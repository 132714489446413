.hero_show_off {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 14px;
}

.card_info {
  position: absolute;
  top: 16px;
}
