@import "shared/styles/media.scss";
@import "shared/styles/typography.scss";

.order_review {
  padding: 16px;
}

.title {
  @extend %header_2;
}

.order_review_header {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 5px;

  .points_text {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 19.6px */

    background: var(--point-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
