@import "shared/styles/media";

.saleBanner {
  position: relative;
  background: radial-gradient(99.8% 102.55% at 88.23% 86.31%, #00E5FB 0%, #142732 100%) !important;
  overflow: hidden;
  cursor: pointer;
  display: flex;

  &.desktop {
    width: 100%;
    margin: 0 auto;
    height: 140px;
    @include md {
      display: none;
    }
    .content {
      width: fit-content;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 0 auto;
      gap: 13px;
      .heart2 {
        width: 153.347px;
        height: 146.821px;
        transform: rotate(-15.19deg);
        position: absolute;
        top: 10px;
        left: -360px;
        z-index: 3;
      }
      .tshirt {
        width: 132px;
        height: 132px;
        position: absolute;
        top: -12px;
        left: -232px;
        z-index: 2;
      }
      .hoodie {
        width: 192px;
        height: 192px;
        position: absolute;
        left: -170px;
        top: 9px;
        z-index: 2;
      }
      .sneaker {
        width: 232.813px;
        height: 232.813px;
        transform: rotate(-11.365deg);
        position: absolute;
        right: -165.34px;
        top: -37.07px;
        z-index: 2;
      }
      .heart1 {
        width: 178.939px;
        height: 171.325px;
        transform: rotate(17.941deg);
        position: absolute;
        right: -300px;
        top: -30.01px;
        z-index: 2;
      }
      .starBackground {
        position: absolute;
        right: -453px;
        z-index: 1;
        top: -120px;
      }
      .starImage {
        position: absolute;
        left: -310px;
        z-index: 2;
        top: 20px;
      }
    }
  }

  &.tablet {
    width: 100%;
    height: 144px;
    .content {
      width: fit-content;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 13px;
      .titleGroup {
        h2 {
          font-family: var(--font);
          text-align: center;
          b {
            font-size: 31px;
            font-weight: 800;
            letter-spacing: -0.31px;
          }
          font-size: 26px;
          font-weight: 500;
          text-transform: none;
          line-height: 100%;
          letter-spacing: -0.26px;
        }
        h3 {
          font-family: var(--font);
          font-size: 16px;
          letter-spacing: -0.16px;
        }
      }
      .designButton {
        font-family: var(--font);
        padding: 10px 10px 10px 15px;
        border-radius: 8px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%;
        svg {
          width: 15px;
          height: 15px;
        }
      }
      .heart2 {
        width: 50.75px;
        height: 48.59px;
        transform: rotate(-15.19deg);
        position: absolute;
        top: 78px;
        left: -161px;
        z-index: 2;
      }
      .tshirt {
        width: 91px;
        height: 91px;
        position: absolute;
        top: -2px;
        left: -151px;
        z-index: 2;
      }
      .hoodie {
        width: 140.804px;
        height: 140.804px;
        position: absolute;
        left: -108px;
        top: 39px;
        z-index: 2;
      }
      .sneaker {
        width: 191.833px;
        height: auto;
        transform: rotate(-12.515deg);
        position: absolute;
        right: -155.71px;
        top: -21.71px;
        z-index: 2;
      }
      .heart1 {
        width: 92.113px;
        height: 88.194px;
        transform: rotate(17.941deg);
        position: absolute;
        right: -177.5px;
        top: 50.43px;
        z-index: 2;
      }
      .starBackground {
        position: absolute;
        z-index: 1;
        right: -266px;
        top: -60px;
      }
      .starImage {
        position: absolute;
        z-index: 1;
        left: -151px;
        transform: rotate(-9.529deg);
      }
    }

    @include md {
      display: none;
    }
  }

  &.mobile {
    width: 100%;
    height: 140px;
    display: none;

    .content {
      padding: 15px;
      display: flex;
      flex-direction: column;
      gap: 13px;
      z-index: 3;
      .titleGroup {
        gap: 4px;
        align-items: flex-start;
        h2 {
          font-size: 22px;
          letter-spacing: -0.66px;
        }
        h3 {
          font-size: 14px;
          letter-spacing: -0.14px;
        }
      }
      .designButton {
        font-size: 12px;
        padding: 6px 8px 6px 11px;
        svg {
          width: 14px;
          height: 14px;
        }
      }
    }

    .heart2 {
      width: 37.836px;
      height: 36.226px;
      transform: rotate(-15.19deg);
      position: absolute;
      top: 0;
      right: 124px;
      z-index: 3;
    }
    .tshirt {
      width: 80px;
      height: 80px;
      position: absolute;
      top: -10px;
      right: -18px;
      z-index: 2;
    }
    .hoodie {
      width: 63px;
      height: 63px;
      position: absolute;
      right: 127px;
      top: 34px;
      z-index: 2;
    }
    .sneaker {
      width: 173.044px;
      height: auto;
      transform: rotate(-14.822deg);
      position: absolute;
      right: 8px;
      top: -12.07px;
      z-index: 3;
    }
    .heart1 {
      width: 140.724px;
      height: 134.736px;
      transform: rotate(17.941deg);
      position: absolute;
      right: -54px;
      bottom: -30px;
      z-index: 4;
    }
    .starBackground {
      position: absolute;
      z-index: 1;
      right: -70px;
      top: -38px;
    }
    .starImage {
      position: absolute;
      z-index: 2;
      right: 84px;
      top: 22px;
      transform: rotate(-9.529deg);
    }

    @include md {
      display: block;
    }
  }
}

.content {
  position: relative;
  z-index: 2;
  .titleGroup {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: var(--font-roboto-condensed);
    h2 {
      font-size: 32px;
      font-weight: 400;
      line-height: 110%;
      letter-spacing: -0.32px;
      text-transform: uppercase;
      color: #FFFFFF;
      b {
        font-weight: 900;
      }
    }
    h3 {
      font-size: 22px;
      font-weight: 400;
      line-height: 120%;
      letter-spacing: -0.22px;
      color: #FFFFFF;
    }
  }
  .designButton {
    display: flex;
    width: fit-content;
    font-family: var(--font-roboto-condensed);
    padding: 8px 10px 8px 15px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 110%;
    color: #FFFFFF;
    background: #000000;
  }
}