.main_photo {
  pointer-events: none;
  position: relative;
  display: flex;
  width: 95%;
  margin-left: auto;
  min-height: 600px;

  img {
    margin-left: auto;
    width: 100%;
    height: auto;

    //top: 5px;
    right: -10px;

    position: relative;
    z-index: 1;
  }
}

.shadow {
  //display: none;
  height: calc(100% - 10px);
  width: 38px;
  z-index: 0;
  //transform: rotate(90deg);

  position: absolute;
  right: -15px;

  top: 0;
  border-radius: 770px;
  background: radial-gradient(50% 50% at 50% 50%, #525252 42.5%, rgba(84, 84, 84, 0) 100%);
}
