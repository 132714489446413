.tags {
  display: flex;
  gap: 10px;

  color: var(--Dark-gray, #92929f);
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
