.name_and_heart {
}

.title {
  display: flex;
}

.name {
  flex: 1;

  color: var(--Black, #222);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.heart {
  --color: var(--Black, #222);
  height: 38px !important;
}
