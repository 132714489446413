.hero {
  width: calc(100% + var(--container-horizontal-padding) * 2);

  position: relative;
  padding-top: 16px;
  padding-bottom: 8px;

  margin: 0 var(--container-horizontal-padding-negative);
}

.image {
  padding: 0 5px;
  min-height: 190px;
}

.original {
  position: absolute !important;
  left: var(--container-horizontal-padding);
  top: 16px;
}

.name {
  margin-top: 4px;
  padding-left: var(--container-horizontal-padding);
  padding-right: 8px;
}

.tags {
  margin-top: 4px;
  padding: 0 var(--container-horizontal-padding);
}
