.original {
  position: relative;
  width: fit-content;
}

.original_main {
  display: flex;
  gap: 4px;
  align-items: center;

  color: #58c94e;

  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.help {
  margin-top: 1.5px;

  display: flex;
  gap: 4px;
  align-items: center;

  color: var(--Text-black, #222);
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px; /* 100% */
}
