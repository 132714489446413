.container {
  width: 100%;
  display: flex;
  padding: 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  font-family: var(--font);
  h3 {
    color: #000;
    font-size: 22px;
    font-weight: 700;
    line-height: 130%;
  }
  p {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
  }
  button {
    width: 100%;
  }
}