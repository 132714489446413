.skeleton {
  animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  background-color: var(--ui-main-light-gray);
}

@keyframes pulse {
  50% {
    opacity: 0.5;
  }
}
